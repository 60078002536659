import React from 'react'

const countryCodes = {
    'IT': 'Italy',
    'US': 'United States',
    'GB': 'United Kingdom',
    'DE': 'Germany',
    'FR': 'France',
    'ES': 'Spain',
    'CA': 'Canada',
    'NL': 'Netherlands',
    'SE': 'Sweden',
    'NO': 'Norway',
    'DK': 'Denmark',
    'FI': 'Finland',
    'AU': 'Australia',
    'NZ': 'New Zealand',
    'BE': 'Belgium',
    'AT': 'Austria',
    'CH': 'Switzerland',
    'IE': 'Ireland',
    'CZ': 'Czech Republic',
    'PL': 'Poland',
    'RU': 'Russia',
    'UA': 'Ukraine',
    'BG': 'Bulgaria',
    'RO': 'Romania',
    'HU': 'Hungary',
    'PT': 'Portugal',
    'BR': 'Brazil',
    'MX': 'Mexico',
    'AR': 'Argentina',
    'CL': 'Chile',
    'CO': 'Colombia',
    'PE': 'Peru',
    'VE': 'Venezuela',
    'EC': 'Ecuador',
    'CR': 'Costa Rica',
    'SV': 'El Salvador',
    'GT': 'Guatemala',
    'HN': 'Honduras',
    'NI': 'Nicaragua',
    'PA': 'Panama',
    'DO': 'Dominican Republic',
    'PR': 'Puerto Rico',
    'UY': 'Uruguay',
    'PY': 'Paraguay',
    'BO': 'Bolivia',
    'JP': 'Japan',
    'KR': 'South Korea',
    'CN': 'China',
    'SG': 'Singapore',
    'MY': 'Malaysia',
    'TH': 'Thailand',
    'PH': 'Philippines',
    'ID': 'Indonesia',
    'VN': 'Vietnam',
    'IN': 'India',
    'PK': 'Pakistan',
    'BD': 'Bangladesh',
    'TR': 'Turkey',
    'SA': 'Saudi Arabia',
    'AE': 'United Arab Emirates',
    'UA': 'Ukraine',
    'KZ': 'Kazakhstan',
    'EG': 'Egypt',
    'ZA': 'South Africa',
    'KE': 'Kenya',
    'NG': 'Nigeria',
    'GH': 'Ghana',
    'TZ': 'Tanzania',
    'MA': 'Morocco',
    'DZ': 'Algeria',
    'TN': 'Tunisia',
    'LY': 'Libya',
    'JO': 'Jordan',
    'LB': 'Lebanon',
    'SY': 'Syria',
    'IQ': 'Iraq',
    'IR': 'Iran',
    'IL': 'Israel',
    'KW': 'Kuwait',
    'OM': 'Oman',
    'QA': 'Qatar',
    'YE': 'Yemen',
    'MK': 'Macedonia',
    'GR': 'Greece',
    'CY': 'Cyprus',
    'AL': 'Albania',
    'SI': 'Slovenia',
    'HR': 'Croatia',
    'BA': 'Bosnia and Herzegovina',
    'RS': 'Serbia',
    'ME': 'Montenegro',
    'MD': 'Moldova',
    'SK': 'Slovakia',
    'LT': 'Lithuania',
    'LV': 'Latvia',
    'EE': 'Estonia',
    'BY': 'Belarus',
    'AM': 'Armenia',
    'AZ': 'Azerbaijan',
    'GE': 'Georgia',
    'IS': 'Iceland',
    'MT': 'Malta',
    'FO': 'Faroe Islands',
    'GL': 'Greenland',
    'AD': 'Andorra',
    'SM': 'San Marino',
    'VA': 'Vatican City',
    'MC': 'Monaco',
    'LI': 'Liechtenstein',
    'LU': 'Luxembourg',
    


 

   
  };


function  Nations ({ prefix }) {
    const countryName = countryCodes[prefix];
    return (
      <span>{countryName || 'Unknown Nation'}</span>
    );
  }

export default Nations